import {
  AuthorAggregateBasic,
  EMailComplete,
  EMailPartial,
  NoteCompleteTypeEnum,
  SubmissionPartial,
  TextBasic,
  TextComplete,
  TextPartial,
  TextRating,
} from '@skribi/openapi/src/text-api/models';
import { ResponseErrorsEnum } from '@skribi/shared/src/resources/error';

export interface QueryStudentTextParams {
  updateListCount?: boolean;
}

export interface QueryStudentTextRequest {
  _class?: Array<string>;
  author?: Array<string>;
  access?: Array<string>;
  submissionType?: Array<string>;
  submissionStatus?: Array<string>;
  submissionTask?: Array<string>;
  submissionReviewer?: Array<string>;
  category?: Array<string>;
  search?: string;
  reported?: Array<string>;
  offset?: number;
  limit?: number;
  aggregate?: boolean;
  updateListCount?: boolean;
}

export interface CreateTextRequest {
  text: TextComplete | EMailComplete;
}

export interface UpdateTextRequest {
  id: string;
  body?: TextPartial | EMailPartial;
  silentMode?: boolean;
}

export interface DeleteTextRequest {
  id: string;
}

export interface UpdateSubmissionStatusRequest {
  id: string;
  body?: SubmissionPartial;
}

export interface GetTextRequest {
  id: string;
}

export interface QueryTextsFromTasksParams {
  tasksId: Array<string>;
  classId: string;
}

export interface GetMultipleTextsByIdRequest {
  id: Array<string>;
}

export interface getTaskTextAndFeedbacksByTextIdParams {
  textId: string;
  classId: string;
  userId?: string;
  isTeacher: boolean;
  noteType: NoteCompleteTypeEnum;
}

export interface QueryTextNoEffectsResponse {
  textList: Array<TextBasic>;
  textCounter: number;
  aggregateList: Array<AuthorAggregateBasic>;
}

export interface QueryHomeTextsProps {
  classId: string;
  userId: string;
}

export type QueryClassDashboardTextsResult = {
  reported: number;
  inReview: number;
};

export interface TextActions {
  queryText(queryParams: QueryStudentTextRequest): Promise<void>;
  queryTextWithCallback(
    queryParams: QueryStudentTextRequest & { onEnd: (textList: Array<TextBasic> | null) => void },
  ): Promise<void>;
  queryTextNoEffects(queryParams: QueryStudentTextRequest): Promise<QueryTextNoEffectsResponse | undefined>;
  createText(requestParams: CreateTextRequest & { onEnd: (ok: TextComplete | undefined) => void }): Promise<void>;
  clearTextError(): void;
  getText(requestParam: GetTextRequest): Promise<void>;
  updateText(requestParam: UpdateTextRequest & { onEnd: (ok: boolean) => void }): Promise<void>;
  deleteText(requestParam: DeleteTextRequest & { onEnd: (ok: boolean) => void }): Promise<void>;
  clearLoadedText(): void;
  updateSubmissionStatus(requestParam: UpdateSubmissionStatusRequest & { onEnd: (ok: boolean) => void }): Promise<void>;
  queryTextsFromTasks(queryParams: QueryTextsFromTasksParams): Promise<void>;
  getMultipleTextsById(
    queryParams: GetMultipleTextsByIdRequest & { onEnd: (textList: Array<TextComplete>) => void },
  ): Promise<void>;
  getTextAndTask(queryParams: GetTextRequest): Promise<void>;
  getTaskTextAndFeedbacksByTextId(requestParams: getTaskTextAndFeedbacksByTextIdParams): Promise<void>;
  queryTextAndNotes(requestParams: QueryStudentTextRequest): Promise<void>;
  queryHomeTexts(requestParam: QueryHomeTextsProps & { onEnd: (textList: Array<TextBasic>) => void }): Promise<void>;
  queryClassDashboardTexts(
    queryParams: QueryStudentTextRequest & { onEnd: (result: QueryClassDashboardTextsResult) => void },
  ): Promise<void>;
  clearTextList(): void;
}

export interface TextState {
  isRequestInProgress: boolean;
  loadedText: TextComplete | null;
  textList: Array<TextBasic>;
  error: ResponseErrorsEnum | null;
  listCounter: number | null;
  actions: TextActions;
  textAggregateList: Array<AuthorAggregateBasic>;
  textRating: TextRating | null;
}

export enum ReportedTypeEnum {
  ANY = 'any',
  TEXT = 'text',
  COMMENT = 'comment',
  FEEDBACK = 'feedback',
}
